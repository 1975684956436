<template>
  <div id="theme--tuktuk__checkout__shipping">
    <div
      v-if="
        data.addShipping &&
        shippingType == 'automated' &&
        data.payload.address &&
        data.courierOptionDataFinal &&
        data.courierOptionDataFinal.length > 0 &&
        (data.courierOptionDataFinal.length > 1 ||
          (data.courierOptionDataFinal.length == 1 && !data.shippingHideView))
      "
      class="theme--tuktuk__checkout__shipping__automated theme--tuktuk__card"
    >
      <div class="theme--tuktuk__container">
        <div class="theme--tuktuk__checkout__shipping__automated__header">
          <h2 class="theme--tuktuk__title">{{ data.titleShipping }}</h2>
          <!-- Pre Order Info -->
          <!-- <p class="m-0 fs-14 fw-400" style="color: #15171C; opacity: 0.4; font-size: 14px;">From West Jakarta to Semarang</p> -->
          <div v-if="data.preOrder != undefined && data.preOrder.isActive" class="pre-order alert flex items-center m-0" style="font-size: 14px;">
            <img src="@/assets/icons/TuktukTheme/calendar-wait.svg" alt="Calendar" />
            <span class="fs-14 fw-400">{{ preOrderText }}</span>
          </div>
        </div>
        <div class="theme--tuktuk__checkout__shipping__automated__list">
          <div
            v-for="(options, index) in data.courierOptionDataFinal"
            v-bind:key="`shipping-provider-${index}`"
            class="theme--tuktuk__checkout__shipping__automated__list__provider"
          >
            <vs-radio
              @click="changeCouriePrice"
              :val="options.value"
              v-model="deliveryCourierPrice"
              class="theme--tuktuk__radio theme--tuktuk__checkout__shipping__automated__list__provider__radio courierOptionsWithPrice"
            >
              <div class="theme-tuktuk-shipping-courier-and-shipFrom-address">
                <div class="tuktuk-courier-and-cheapest-wrapper flex">
                  <div class="theme--tuktuk__checkout__shipping__automated__list__provider__radio__left">
                    <img
                      width="30px"
                      :alt="options.key"
                      :src="baseUrl+shippingCourierItems[options.key].imageSrc"
                    />
                    <span>{{ options.label.split('-').length ? options.label.split('-')[0]+'-'+options.label.split('-')[1] : '' }}</span>
                  </div>
                  <div v-if="isCheapest(options.label,data.courierOptionDataFinal)">
                    <span class="cheapest-label tuktuk-theme ml-1">{{ $t("common.cheapest") }}</span>
                  </div>
                </div>
                <p class="shipping-address m-0">{{ options.shipFromAddress ? 'Dikirim dari ' + options.shipFromAddress.CITY_NAME : ''}}</p>
              </div>  
              <div class="theme--tuktuk__checkout__shipping__automated__list__provider__radio__rigth">
                {{ options.label2.split('-').length > 2 ?  options.label2.split('-')[2] : 'Rp0' }}
              </div>
            </vs-radio>
          </div>
          <div v-if="data.errors['deliveryCourierPrice']" class="invalid-feedback" >
            {{ data.errors["deliveryCourierPrice"] }}
          </div>
        </div>
      </div>
    </div>

    <!-- SHIPPING FLAT AND FREE-->
    <div
      v-if="data.addShipping && shippingType !== 'automated'"
      class="theme--tuktuk__checkout__shipping__flat-free theme--tuktuk__card"
    >
      <div class="theme--tuktuk__container">
        <h2 class="theme--tuktuk__title">{{ data.titleShipping }}</h2>
        <div class="theme--tuktuk__checkout__shipping__flat-free__content">
          <!-- FLAT -->
          <template v-if="shippingType == 'flat'">
            <p>
              {{ $t("common.shipping") }} {{ shippingType }}
            </p>
            <p v-if="shippingType == 'flat' && flatShippingPrice">
              Rp{{ flatShippingPrice | g_number_format }}
            </p>
          </template>
          <!-- FREE -->
          
          <template v-if="shippingType == 'free'">
            <p class="free-shippingType-title">
              {{ shippingType }} {{ $t("common.shipping") }}
            </p>
            <p class="flat-free-title">Rp0</p>
          </template>
        </div>
      </div>
    </div>
    <!-- END SHIPPING -->
  </div>
</template>

<script>
import config from "../../../../config.js";
export default {
  props: ["data", "shippingCourierItems"],
  data() {
    return {
      baseUrl: config.api.replace("/api", ""),
      deliveryCourierPrice: "",
    };
  },
  components: {},
  watch: {
    "data.deliveryCourierPrice": {
      handler() {       
        this.deliveryCourierPrice = this.data.deliveryCourierPrice;
      },
    },
    deliveryCourierPrice: {
      async handler() {
        let price = this.deliveryCourierPrice;    
        if (price) {
          let sellerOriginId = null;
          this.data.courierOptionDataFinal.forEach(option => {
            if (option.value == price) {
              sellerOriginId = option.origin_id;
            }
          });
          price = price.split("==");
          let priceLabel = this.data.courierOptionDataFinal.find(
            (x) => x.value == this.deliveryCourierPrice
          );
          if (priceLabel) {
            priceLabel = priceLabel.label.split("-");
            this.data.payload.deliveryCourier = price[0];
            this.data.payload.deliveryPrice = price[1];
            this.data.payload.sellerOriginId = sellerOriginId;
            this.data.payload.deliveryType = priceLabel[1].trim()
              ? priceLabel[1].trim()
              : "REG";
            this.$forceUpdate();
          }
        }
      },
      deep: true,
    },
  },
  computed:{
    isPaymentCOD: {
      get() {
        return this.data.payload.payment === 'COD';
      }
    },

    shippingType: {
      get() {
        if (this.isPaymentCOD) {
           return this.data.shippingCOD.shippingType;
        }
        return this.data.shippingType;
      }
    },

    flatShippingPrice: {
      get() {
        if (this.isPaymentCOD) {
           return this.data.shippingCOD.flatShippingPrice || 0;
        }
        return this.data.flatShippingPrice || 0;
      }
    },
    preOrderText() {
      return this.$t('common.preOrder').replace(/x/g, this.data.preOrder.packagingDueDay);
    },
  },
  methods: {
    isCheapest(label, optionsData) {
      const prices = optionsData.map(option => parseInt(option.label.split('-')[2].replace('Rp', '').replace(',', '')));
      const currentPrice = parseInt(label.split('-')[2].replace('Rp', '').replace(',', ''));
      return Math.min(...prices) == currentPrice;
    },
    changeCouriePrice() {
      let price = this.deliveryCourierPrice;   
      if (price) {
        let sellerOriginId = null;
        this.data.courierOptionDataFinal.forEach(option => {
          console.log(option.value == price);
          if (option.value == price) {
            sellerOriginId = option.origin_id;
          }
        });
        this.data.deliveryCourierPrice = this.deliveryCourierPrice;
        price = price.split("==");
        let priceLabel = this.data.courierOptionDataFinal.find(
          (x) => x.value == this.deliveryCourierPrice
        );
        priceLabel = priceLabel.label.split("-");
        this.data.payload.deliveryCourier = price[0];
        this.data.payload.deliveryPrice = price[1];
        this.data.payload.sellerOriginId = sellerOriginId;
        this.data.payload.deliveryType = priceLabel[1].trim()
          ? priceLabel[1].trim()
          : "REG";
        this.$forceUpdate();
      }
    },
  },
  mounted() {
    this.deliveryCourierPrice = this.data.deliveryCourierPrice;
  },
};
</script>

<style scoped>
.cheapest-label.tuktuk-theme {
  background: #EB435B1A;
  color: #EB435B;
  padding: 4px 8px;
  border-radius: 4px;
}
.courierOptionsWithPrice {
  justify-content: space-between;
  align-items: center;
  h6.types-ofcourier {
    font-weight: 500;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  p.shipping-address {
    font-weight: 400;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  h6.shipping-price {
    font-weight: 600;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
  }
}
</style>
