module.exports = {
    api: 'https://app.formulir.com/api/',
    isDev: false,
    baseUrl: 'https://form.id',
    googleCaptchaKey: '8133ec97-b0eb-43fe-bbc4-710ea27745c5',
    redirectUrl: 'https://form.id/',
    imagekit:{
        thumbnail:"https://ik.imagekit.io/mengantar/gcs/tr:n-ik_thumbnail",
        medium: "https://ik.imagekit.io/mengantar/gcs/tr:n-ik_medium",
        full:"https://ik.imagekit.io/mengantar/gcs/tr:n-ik_full/"      
    },
    // shippingUrl:"https://research.mengantar.com/api/"
    shippingUrl:"https://app.mengantar.com/api/" 
}
